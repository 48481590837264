defer(function () {
   var strBackgroundImage = $("body").css("background-image"),
      strUrl = strBackgroundImage.substr(5, strBackgroundImage.length - 7),
      img = new Image(),
      jImg,
      jWindow = $(window),
      iImgWidth,
      iImgHeight,
      iImgRatio;

   function resize () {
      var iWindowHeight = jWindow.height(),
         iWindowWidth = jWindow.width(),
         iWindowRatio = iWindowWidth / iWindowHeight,
         iNewImageHeight,
         iNewImageWidth;

      if (iWindowRatio > iImgRatio) {
         iNewImageHeight = iWindowWidth / iImgRatio;
         jImg.css({
            position: "fixed",
            left: 0,
            top: Math.floor((iNewImageHeight - iWindowHeight) / -2),
            width: "100%",
            height: "auto"
         });
      } else {
         iNewImageWidth = iWindowHeight * iImgRatio;
         jImg.css({
            position: "fixed",
            top: 0,
            left: Math.floor((iNewImageWidth - iWindowWidth) / -2),
            width: "auto",
            height: "100%"
         });
      }
   }

   function setupThrottledResize () {
      var timeout;
      jWindow.resize(function () {
         clearTimeout(timeout);
         setTimeout(resize, 20);
      });
   }

   function initialize () {
      img.onload = function () {
         jImg = $(img);
         $(document.body).prepend(jImg);
         iImgWidth = jImg.width();
         iImgHeight = jImg.height();
         iImgRatio = iImgWidth / iImgHeight;
         setupThrottledResize();
         resize();
      };
      img.onerror = function (e) {
         // Not loaded
      };
      img.src = strUrl;
   }

   initialize();
});
